<template>
  <section
    class="bg-[url('/images/background.jpeg')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-70 w-full h-full"
  >
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen pt:mt-0"
    >
      <div
        class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800"
      >
        <div class="flex flex-row justify-center">
          <img
            class="w-[100px] h-auto mr-2 text-white invert dark:invert-0"
            src="/logo/terra-logo.svg"
            alt="logo"
          />
        </div>

        <div
          class="p-6 pt-0 sm:pt-0 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8"
        >
          <h1
            class="text-lg font-medium text-center text-gray-900 md:text-xl dark:text-white"
          >
            ลงชื่อเข้าใช้บัญชีของคุณ
          </h1>

          <Form
            class="space-y-4 md:space-y-6"
            :validation-schema="schema"
            #="{ setValues, handleSubmit }"
            @submit="handleSubmit"
          >
            <text-field
              name="email"
              label="อีเมล"
              placeholder="อีเมล"
              icon-name="envelope"
            />
            <text-field
              type="password"
              name="password"
              label="รหัสผ่าน"
              placeholder="รหัสผ่าน"
              icon-name="lock"
            />

            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center mr-4">
                  <input
                    id="primary-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="primary-checkbox"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >จำฉันไว้ในระบบ</label
                  >
                </div>
              </div>
              <a
                class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 cursor-pointer"
                @click="navigateTo('/forgot-password')"
                >ลืมรหัสผ่าน</a
              >
            </div>
            <div v-auto-animate="{ duration: 200 }" class="!mt-0">
              <div
                v-if="alertMessage"
                class="flex p-3 !mt-3 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
              >
                <div>
                  {{ alertMessage }}
                </div>
              </div>
            </div>

            <basic-button type="submit" text="เข้าสู่ระบบ" />
          </Form>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { Form } from "vee-validate";
import { object } from "yup";
import TextField from "~/components/elements/textField/TextField.vue";
import BasicButton from "~/components/elements/button/basicButton.vue";

const { fieldEmail, fieldPassword } = useFieldSchema();

const alertMessage = ref("");

const { login } = useLogin();

definePageMeta({
  layout: false,
  middleware: [
    function (to) {
      const { query } = to;
      if (!query.terminal) {
        const token = useCookie("auth.token");
        const isLogged = Boolean(token.value);
        if (isLogged) {
          return navigateTo("/branch-selector");
        }
      }
    },
  ],
});

const schema = object({
  email: fieldEmail("อีเมล"),
  password: fieldPassword("รหัสผ่าน"),
});

const handleSubmit = async (values: any, actions: any) => {
  const { email, password } = values;
  const payload = {
    email,
    password,
  };
  alertMessage.value = "";
  await login(payload)
    .then(() => {
      navigateTo("/branch-selector");
      // reset table's preferences in local storage
      localStorage.removeItem("table-query");
    })
    .catch(({ data: error }) => {
      if (error?.errors) {
        actions.setErrors(error.errors);
      } else {
        alertMessage.value = error?.message;
      }
    });
};
</script>
